<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getXML()"
      :loading="loading">
      <v-icon left>mdi-file-xml</v-icon> Gerar XML (SEM Cobrança Parcial)
    </v-btn>
  </v-container>
</template>

<script>
import api from "../../../http";
import moment from "moment";
import { create } from 'xmlbuilder2';
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import md5 from 'md5';

const { withCRUDUtils } = UtilsFunc

// Função para trocar acentos por item sem acentuação e remover caracter especial
function cleanText(input) {
  const accentsMap = {
    'á': 'a', 'Á': 'A', 'à': 'a', 'À': 'A', 'ã': 'a', 'Ã': 'A', 'â': 'a', 'Â': 'A',
    'é': 'e', 'É': 'E', 'è': 'e', 'È': 'E', 'ê': 'e', 'Ê': 'E',
    'í': 'i', 'Í': 'I', 'ì': 'i', 'Ì': 'I', 'î': 'i', 'Î': 'I',
    'ó': 'o', 'Ó': 'O', 'ò': 'o', 'Ò': 'O', 'õ': 'o', 'Õ': 'O', 'ô': 'o', 'Ô': 'O',
    'ú': 'u', 'Ú': 'U', 'ù': 'u', 'Ù': 'U', 'û': 'u', 'Û': 'U',
    'ç': 'c', 'Ç': 'C',
    'ü': 'u', 'Ü': 'U'
  };
  let output = input.split('').map(char => accentsMap[char] || char).join('');
  return output.replace(/[^a-zA-Z0-9\s]/g, '');
}
function formatCurrency(value) {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

function formatarCID(cid) {
    if (!cid) return '';
    // Remove qualquer caractere que não seja letra ou número
    const cidLimpo = cid.replace(/[^a-zA-Z0-9]/g, '');
    // Retorna apenas os 3 primeiros caracteres
    return cidLimpo.substring(0, 3);
}

export default {
  name: "XMLAgrupado",
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
  },
  data: () => withCRUDUtils({
    empresa: [],
    procedimentos: [],
    equipamentos: [],
    procedimentos2: [],
    taxas: [],
    matmed: [],
    pacotes: [],
    combinedData: [],
    loading: false,
  }),
  methods: {
    async fetchAllData() {
      this.loading = true;
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimento(),
          this.getEquipamentos(),
          this.getTaxa(),
          this.getPacote(),
          this.getMatMed(),
        ]);

        this.combinedData = [
          ...this.procedimentos2,
          ...this.equipamentos,
          ...this.taxas,
          ...this.pacotes,
          ...this.matmed,
        ];

        this.loading = false;
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
        this.$toast.error("Erro ao carregar os dados: " + error.message);
        this.loading = false;
      }
    },
    async getXML() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await this.fetchAllData();
        const valorTotalGeral = this.generateXML(); // Chamar generateXML e armazenar o valor retornado
        const valorFormatado = formatCurrency(valorTotalGeral); // Formatar o valor
        this.$toast.success(`O download do XML foi realizado com sucesso! Valor Total: ${valorFormatado}`);
        this.loading = false;
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
        this.$toast.error("Erro ao carregar os dados: " + error.message);
        this.loading = false;
      }
    },
    generateXML() {
      const empresa = this.empresa;
      const selectedGuides = this.selectedBudgets;
      this.valorDiarias = 0;
      this.valorProcedimentos = 0;
      this.valorTaxasAlugueis = 0;
      this.valorTotalGeral = 0;

      const totalValuePerBudget = selectedGuides.reduce((acc, id) => {
        let valorDiarias = 0;
        let valorProcedimentos = 0;
        let valorTaxasAlugueis = 0;

        if (this.procedimentos[id]) {
          this.procedimentos[id].forEach(item => {
            valorProcedimentos += item?.valor_cobranca_final || 0;
          });
        }

        if (this.procedimentos2[id]) {
          this.procedimentos2[id].forEach(item => {
            if ([43, 44, 45, 46].includes(item?.procedimento?.procedimento_id)) {
              valorDiarias += item?.valor_cobranca_final || 0;
            } else {
              valorTaxasAlugueis += (item?.valor_cobranca_final || 0) + (item?.valor_cobranca_final_taxa || 0) + (item?.valor_cobranca_final_equipamento || 0) + (item?.valor_cobranca_final_pacote || 0) + (item?.valor_cobranca_final_matmed || 0);
            }
          });
        }

        if (this.taxas[id]) {
          this.taxas[id].forEach(item => {
            valorTaxasAlugueis += item?.valor_cobranca_final_taxa || 0;
          });
        }

        if (this.equipamentos[id]) {
          this.equipamentos[id].forEach(item => {
            valorTaxasAlugueis += item?.valor_cobranca_final_equipamento || 0;
          });
        }
        if (this.matmed[id]) {
          this.matmed[id].forEach(item => {
            valorTaxasAlugueis += item?.valor_cobranca_final_matmed || 0;
          });
        }

        if (this.pacotes[id]) {
          this.pacotes[id].forEach(item => {
            valorTaxasAlugueis += item?.valor_cobranca_final_pacote || 0;
          });
        }

        this.valorDiarias += valorDiarias;
        this.valorProcedimentos += valorProcedimentos;
        this.valorTaxasAlugueis += valorTaxasAlugueis;

        this.valorTotalGeral = this.valorDiarias + this.valorProcedimentos + this.valorTaxasAlugueis;

        acc[id] = {
          valorDiarias: valorDiarias,
          valorProcedimentos: valorProcedimentos,
          valorTaxasAlugueis: valorTaxasAlugueis,
          valorTotalGeral: valorDiarias + valorProcedimentos + valorTaxasAlugueis
        };

        return acc;
      }, {});

      let procedimentos = [];
      let procedimentos2 = [];
      let sequencialItem = 1;
      let nonSpecialProcedures = [];
      let nonSpecialProcedures2 = [];
      let specialProcedures = [];
      let specialProcedures2 = [];
      let despesasProcedimentos2 = [];

      for (const id of selectedGuides) {
        const procedimentosPorId = this.procedimentos[id] || [];  // Garantindo que existe uma lista para o id

        for (const item of procedimentosPorId) {
          let desconto = item?.desconto;
          let acrescimo = item?.acrescimo;
          let percentual = desconto ? -desconto : acrescimo;
          const nomeProcedimento = (item?.procedimento?.nomecomercial  && item.procedimento.nomecomercial.trim() !== "") ? item?.procedimento?.nomecomercial : item?.procedimento?.procedimento; 
          // console.log(item)
          if (!item?.procedimento?.especial) {
            nonSpecialProcedures.push({
              sessaoId: item?.sessao?.id,
              'ans:sequencialItem': sequencialItem++,
              'ans:dataExecucao': item?.timestamp ? UtilsPDF.formatDateXML(item?.timestamp) : item?.data_especial,
              'ans:horaInicial': (item?.sessao?.operadora?.ans === "346659" && item?.sessao?.modalidade?.nome === "SAD") ? '10:00:00' : (item?.hora_inicio ? item?.hora_inicio : '00:00:00'),
              'ans:horaFinal': (item?.sessao?.operadora?.ans === "346659" && item?.sessao?.modalidade?.nome === "SAD") ? '10:40:00' : (item?.hora_fim ? item?.hora_fim : '23:59:59'),
              // 'ans:horaFinal': item?.hora_fim ? item?.hora_fim : '23:59:59',
              'ans:procedimento': {
                'ans:codigoTabela': item?.procedimento.tabela_tabela,
                'ans:codigoProcedimento': item?.procedimento.codigo,
                'ans:descricaoProcedimento': nomeProcedimento,
              },
              'ans:quantidadeExecutada': item?.quantidade,
              'ans:reducaoAcrescimo': percentual ? percentual.toFixed(1) : 1,
              'ans:valorUnitario': item?.valor_cobranca.toFixed(2),
              'ans:valorTotal': item?.valor_cobranca_final.toFixed(2),
              'ans:equipeSadt': {
                'ans:codProfissional': {
                  'ans:cpfContratado': item?.profissional?.cpf ? item?.profissional.cpf : item?.medico.cpf,
                },
                'ans:nomeProf': item?.profissional?.nomecompleto ? item?.profissional.nomecompleto : item?.medico.first_name,
                'ans:conselho': item?.profissional?.conselho?.codigo ? item?.profissional.conselho.codigo : item?.medico.conselho_codigo,
                'ans:numeroConselhoProfissional': item?.profissional?.nconselho ? item?.profissional.nconselho : item?.medico.nconselho,
                'ans:UF': item?.profissional?.uf_inscricao_conselho?.codigo ? item?.profissional.uf_inscricao_conselho.codigo : item?.medico.uf_inscricao_conselho_codigo,
                'ans:CBOS': item?.profissional?.especialidade?.cbo ? item?.profissional.especialidade.cbo : item?.medico.especialidade_cbo,
              }
            });
          }
          if (!item?.procedimento?.especial) {
            nonSpecialProcedures2.push({
              sessaoId: item?.sessao?.id,
              'ans:sequencialItem': sequencialItem++,
              'ans:dataExecucao': item?.timestamp ? UtilsPDF.formatDateXML(item?.timestamp) : item?.data_especial,
              'ans:horaInicial': item?.hora_inicio ? item?.hora_inicio : '00:00:00',
              'ans:horaFinal': item?.hora_fim ? item?.hora_fim : '23:59:59',
              'ans:procedimento': {
                'ans:codigoTabela': item?.procedimento.tabela_tabela,
                'ans:codigoProcedimento': item?.procedimento.codigo,
                'ans:descricaoProcedimento': nomeProcedimento,
              },
              'ans:quantidadeExecutada': item?.quantidade,
              'ans:reducaoAcrescimo': percentual ? percentual.toFixed(1) : 1,
              'ans:valorUnitario': item?.valor_cobranca.toFixed(2),
              'ans:valorTotal': item?.valor_cobranca_final.toFixed(2),
              // 'ans:equipeSadt': {
              //   'ans:codProfissional': {
              //     'ans:cpfContratado': item?.profissional?.cpf ? item?.profissional.cpf : item?.medico.cpf,
              //   },
              //   'ans:nomeProf': item?.profissional?.nomecompleto ? item?.profissional.nomecompleto : item?.medico.first_name,
              //   'ans:conselho': item?.profissional?.conselho?.codigo ? item?.profissional.conselho.codigo : item?.medico.conselho_codigo,
              //   'ans:numeroConselhoProfissional': item?.profissional?.nconselho ? item?.profissional.nconselho : item?.medico.nconselho,
              //   'ans:UF': item?.profissional?.uf_inscricao_conselho?.codigo ? item?.profissional.uf_inscricao_conselho.codigo : item?.medico.uf_inscricao_conselho_codigo,
              //   'ans:CBOS': item?.profissional?.especialidade?.cbo ? item?.profissional.especialidade.cbo : item?.medico.especialidade_cbo,
              // }
            });
          }
        }
      }

      procedimentos = nonSpecialProcedures.concat(specialProcedures);
      procedimentos2 = nonSpecialProcedures2.concat(specialProcedures2);

      let sequencialItemProcedimentos2 = 1;
      // Processando procedimentos2
      for (const item of this.combinedData.flat()) {
        if (!selectedGuides.includes(item?.sessao?.id)) {
          continue;
        }
        let desconto = item?.desconto || item?.desconto_equipamento || item?.desconto_taxa || item?.desconto_pacote || item?.desconto_matmed
        let acrescimo = item?.acrescimo || item?.acrescimo_equipamento || item?.acrescimo_taxa || item?.acrescimo_pacote || item?.acrescimo_matmed
        let percentual = desconto ? -(desconto) : acrescimo

        let valorCobranca = (item?.valor_cobranca || item?.valor_cobranca_equipamento || item?.valor_cobranca_taxa || item?.valor_cobranca_pacote || item?.valor_cobranca_matmed)
        let valorCobrancaFinal = (item?.valor_cobranca_final || item?.valor_cobranca_final_equipamento || item?.valor_cobranca_final_taxa || item?.valor_cobranca_final_pacote || item?.valor_cobranca_final_matmed)


        if ((item?.procedimento?.xmlagrupado || item?.equipamento?.xmlagrupado?.id || item?.taxa?.xmlagrupado?.id || item?.pacote?.xmlagrupado?.id || item?.xmlagrupado?.id) === 2) {
          let codigoDespesa = (item?.procedimento?.despesa?.despesa || item?.equipamento?.despesa?.despesa || item?.taxa?.despesa?.despesa || item?.despesa?.despesa || item?.pacote?.despesa?.despesa )
          let dataExecucaoUnica = moment(item?.data_inicio_especial || item?.data_inicio_equipamento || item?.data_inicio_taxa || item?.data_inicio_pacote || item?.data_inicio_matmed).format('YYYY-MM-DD');
          let dataExecucao = dataExecucaoUnica;
          let quantidadeTodos = (item?.quantidade || item?.quantidade_equipamento || item?.quantidade_taxa || item?.quantidade_pacote || item?.quantidade_matmed)
          let valorTabela = (item?.procedimento?.tabela_tabela || item?.equipamento?.tabela?.tabela || item?.taxa?.tabela?.tabela || item?.tabela?.tabela || item?.pacote?.tabela?.tabela );
          let valorCodigo = (item?.procedimento?.codigo || item?.equipamento?.codigo || item?.taxa?.codigo  || item?.codigo || item?.pacote?.codigo);
          let unidadedeMedida = (item?.procedimento?.unidade_medida || item?.equipamento?.unidade_medida || item?.taxa?.taxa?.unidade_medida || 
            (item?.operadora?.unidade_medida_padrao === true ? '036' : item?.unidade_medida?.codigo) 
            || item?.pacote?.unidade_medida?.codigo )
          let nomeCompletoProcedimento = (item?.procedimento?.nomecomercial  && item.procedimento.nomecomercial.trim() !== "") ? item?.procedimento?.nomecomercial : item?.procedimento?.procedimento;
          let nomeCompetoEquipamento =  (item?.equipamento?.nomecomercial  && item.equipamento.nomecomercial.trim() !== "") ? item?.equipamento?.nomecomercial : item?.equipamento?.equipamento?.nome;
          let nomeCompetoTaxa =  (item?.taxa?.nomecomercial  && item.taxa.nomecomercial.trim() !== "") !== null ? item?.taxa?.nomecomercial : item?.taxa?.taxa?.nome;
          let nomeProcedimento = (nomeCompletoProcedimento  || nomeCompetoEquipamento || nomeCompetoTaxa || item?.matmed?.nome || item?.pacote?.nome )

          for (let i = 0; i < quantidadeTodos; i++) {
            despesasProcedimentos2.push({
              sessaoId: item?.sessao?.id, // Adiciona o identificador da sessão
              'ans:sequencialItem': sequencialItemProcedimentos2++,
              'ans:codigoDespesa': codigoDespesa,
              'ans:servicosExecutados': {
                'ans:dataExecucao': item?.timestamp ? UtilsPDF.formatDateXML(item?.timestamp) : UtilsPDF.formatDateXML(dataExecucao),
                'ans:codigoTabela': valorTabela,
                'ans:codigoProcedimento': valorCodigo,
                'ans:quantidadeExecutada': 1,
                'ans:unidadeMedida': unidadedeMedida ? unidadedeMedida : "036",
                'ans:reducaoAcrescimo': percentual ? percentual.toFixed(1) : 1,
                'ans:valorUnitario': valorCobranca.toFixed(2),
                'ans:valorTotal': (valorCobrancaFinal / quantidadeTodos).toFixed(2),
                'ans:descricaoProcedimento': cleanText(nomeProcedimento),
              },
            });
            dataExecucao = moment(dataExecucao).add(1, 'days').toDate();
          }
        } else {
          let codigoDespesa = (item?.procedimento?.despesa?.despesa || item?.equipamento?.despesa?.despesa || item?.taxa?.despesa?.despesa || item?.despesa?.despesa || item?.pacote?.despesa?.despesa )
          let dataExecucaoUnica = moment(item?.data_inicio_especial || item?.data_inicio_equipamento || item?.data_inicio_taxa || item?.data_inicio_pacote || item?.data_inicio_matmed).format('YYYY-MM-DD');
          let dataExecucao = dataExecucaoUnica;
          let quantidadeTodos = (item?.quantidade || item?.quantidade_equipamento || item?.quantidade_taxa || item?.quantidade_pacote || item?.quantidade_matmed)
          let valorTabela = (item?.procedimento?.tabela_tabela || item?.equipamento?.tabela?.tabela || item?.taxa?.tabela?.tabela || item?.tabela?.tabela || item?.pacote?.tabela?.tabela );
          let valorCodigo = (item?.procedimento?.codigo || item?.equipamento?.codigo || item?.taxa?.codigo || item?.codigo || item?.pacote?.codigo );
          let unidadedeMedida = (item?.procedimento?.unidade_medida || item?.equipamento?.unidade_medida || item?.taxa?.taxa?.unidade_medida || 
            (item?.operadora?.unidade_medida_padrao === true ? '036' : item?.unidade_medida?.codigo) 
            || item?.pacote?.unidade_medida?.codigo )
          let valorcobrancaindividual = ((item?.valor_cobranca || item?.valor_cobranca_equipamento || item?.valor_cobranca_taxa || item?.valor_cobranca_pacote || item?.valor_cobranca_matmed) || 0)
          let valorCobrancaTodos = ((item?.valor_cobranca_final || item?.valor_cobranca_final_equipamento || item?.valor_cobranca_final_taxa || item?.valor_cobranca_final_pacote || item?.valor_cobranca_final_matmed) || 0)
          let nomeCompletoProcedimento = (item?.procedimento?.nomecomercial  && item.procedimento.nomecomercial.trim() !== "") ? item?.procedimento?.nomecomercial : item?.procedimento?.procedimento;
          let nomeCompetoEquipamento =  (item?.equipamento?.nomecomercial  && item.equipamento.nomecomercial.trim() !== "") ? item?.equipamento?.nomecomercial : item?.equipamento?.equipamento?.nome;
          let nomeCompetoTaxa =  (item?.taxa?.nomecomercial  && item.taxa.nomecomercial.trim() !== "") !== null ? item?.taxa?.nomecomercial : item?.taxa?.taxa?.nome;
          let nomeProcedimento = (nomeCompletoProcedimento  || nomeCompetoEquipamento || nomeCompetoTaxa || item?.matmed?.nome || item?.pacote?.nome  )

          despesasProcedimentos2.push({
            sessaoId: item?.sessao?.id, // Adiciona o identificador da sessão
            'ans:sequencialItem': sequencialItemProcedimentos2++,
            'ans:codigoDespesa': codigoDespesa,
            'ans:servicosExecutados': {
              'ans:dataExecucao': item?.timestamp ? UtilsPDF.formatDateXML(item?.timestamp) : UtilsPDF.formatDateXML(dataExecucao),
              'ans:codigoTabela': valorTabela,
              'ans:codigoProcedimento': valorCodigo,
              'ans:quantidadeExecutada': quantidadeTodos,
              'ans:unidadeMedida': unidadedeMedida ? unidadedeMedida : "036",
              'ans:reducaoAcrescimo': percentual ? percentual.toFixed(1) : 1,
              'ans:valorUnitario': valorcobrancaindividual.toFixed(2),
              'ans:valorTotal': valorCobrancaTodos.toFixed(2),
              'ans:descricaoProcedimento': cleanText(nomeProcedimento),
            }
          });
        }
      }

      const menorNumero = Math.min(...selectedGuides);
      const dataAtual = UtilsPDF.formatDateXML(new Date());
      const dataNumerica = parseInt(dataAtual.replace(/\D/g, ''));
      const sequencialTransacao = (dataNumerica / 10000) + menorNumero;

      var obj = {
        'ans:mensagemTISS': {
          '@xsi:schemaLocation': 'http://www.ans.gov.br/padroes/tiss/schemas http://www.ans.gov.br/padroes/tiss/schemas/tissV4_01_00.xsd',
          '@xmlns:ans': 'http://www.ans.gov.br/padroes/tiss/schemas',
          '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
          'ans:cabecalho': {
            'ans:identificacaoTransacao': {
              'ans:tipoTransacao': 'ENVIO_LOTE_GUIAS',
              'ans:sequencialTransacao': Number(sequencialTransacao.toFixed(0)),
              'ans:dataRegistroTransacao': dataAtual,
              'ans:horaRegistroTransacao': moment().format('HH:mm:ss')
            },
            'ans:origem': {
              'ans:identificacaoPrestador': {
                'ans:codigoPrestadorNaOperadora': empresa[0].convenio_codigo ?? 'Não informado'
              }
            },
            'ans:destino': {
              'ans:registroANS': empresa[0].convenio_ans
            },
            'ans:Padrao': empresa[0].versao_xml
          },
          
          'ans:prestadorParaOperadora': {
            'ans:loteGuias': {
              'ans:numeroLote': empresa[0].id ?? 'Não informado',
              'ans:guiasTISS': {

                [`${empresa[0].tipo_xml_sadt === true ? 'ans:guiaSP-SADT' : 'ans:guiaResumoInternacao'}`]: selectedGuides.map(id => {
                  const array = this.procedimentos.flat().filter(item => item?.sessao.id === id);
                  const orcamento = this.empresa.find(item => item.id === id);
                  const filteredExpenses = despesasProcedimentos2.filter(expense => expense.sessaoId === id);
                  const procedures = procedimentos.filter(item => item.sessaoId === id);
                  const procedures2 = procedimentos2.filter(item => item.sessaoId === id);
                  return {
                    'ans:cabecalhoGuia': {
                      'ans:registroANS': orcamento.convenio_ans,
                      'ans:numeroGuiaPrestador': orcamento.nr_guia_prestador
                    },
                    ...(orcamento.tipo_xml_sadt === false && {
                      'ans:numeroGuiaSolicitacaoInternacao': orcamento.nr_guia_prestador,
                    }),
                    'ans:dadosAutorizacao': {
                      'ans:numeroGuiaOperadora': orcamento.nr_guia_operador,
                      'ans:dataAutorizacao': orcamento.senha_data_inicio,
                      'ans:senha': orcamento.senha,
                      'ans:dataValidadeSenha': orcamento.senha_data_validade
                    },
                    'ans:dadosBeneficiario': {
                      'ans:numeroCarteira': orcamento.nr_carteirinha,
                      'ans:atendimentoRN': orcamento.atendimentoRN !== "0" ? 'S' : 'N',
                    },
                    ...(orcamento.tipo_xml_sadt === true && {
                      'ans:dadosSolicitante': {
                        'ans:contratadoSolicitante': {
                          'ans:codigoPrestadorNaOperadora': orcamento.convenio_codigo,
                        },
                        'ans:nomeContratadoSolicitante': cleanText(orcamento.convenio_codigo),
                        'ans:profissionalSolicitante': {
                          'ans:nomeProfissional': cleanText(array[0]?.medico?.first_name) ?? 'Informação incompleta',
                          'ans:conselhoProfissional': array[0]?.medico?.conselho_codigo,
                          'ans:numeroConselhoProfissional': array[0]?.medico?.nconselho,
                          'ans:UF': array[0]?.medico?.uf_inscricao_conselho_codigo,
                          'ans:CBOS': array[0]?.medico?.especialidade_cbo
                        }
                      },
                      'ans:dadosSolicitacao': {
                        'ans:caraterAtendimento': orcamento.tipo_carater_atendimento,
                        'ans:indicacaoClinica': orcamento.modalidade
                      },
                    }),
                    'ans:dadosExecutante': {
                      'ans:contratadoExecutante': {
                        'ans:codigoPrestadorNaOperadora': orcamento.convenio_codigo,
                      },
                      'ans:CNES': orcamento.empresa_cnes,
                    },
                    ...(orcamento.tipo_xml_sadt === true && {
                      'ans:dadosAtendimento': {
                        'ans:tipoAtendimento': orcamento.tipoatendimento_codigo,
                        'ans:indicacaoAcidente': orcamento.indicacaoacideente_codigo,
                        'ans:regimeAtendimento': orcamento.regimeatendimento_codigo
                      },
                    }),
                    ...(orcamento.tipo_xml_sadt === false && {
                      'ans:dadosInternacao': {
                        'ans:caraterAtendimento': orcamento.tipo_carater_atendimento,
                        'ans:tipoFaturamento': orcamento.tipo_faturamento,
                        'ans:dataInicioFaturamento': orcamento.data_inicio,
                        'ans:horaInicioFaturamento': "00:00:00",
                        'ans:dataFinalFaturamento': orcamento.data_fim,
                        'ans:horaFinalFaturamento': "23:59:59",
                        'ans:tipoInternacao': orcamento.tipo_internacao,
                        'ans:regimeInternacao': orcamento.tipo_regime_internacao,
                      },
                      'ans:dadosSaidaInternacao': {
                        'ans:diagnostico': formatarCID(orcamento.cid),
                        'ans:indicadorAcidente': orcamento.indicacaoacideente_codigo,
                        'ans:motivoEncerramento': orcamento.tipo_motivo_encerramento,
                      },
                    }),
                    ...((orcamento.tipo_xml_sadt === true ) && {
                      'ans:procedimentosExecutados': {
                        'ans:procedimentoExecutado': procedures.map((procedure, index) => {
                          delete procedure.sessaoId;
                          return {
                            ...procedure,
                            'ans:sequencialItem': index + 1 // Define o sequencialItem como index + 1
                          };
                        })
                      }
                    }),
                    ...((orcamento.tipo_xml_sadt === false ) && {
                      'ans:procedimentosExecutados': {
                        'ans:procedimentoExecutado': procedures2.map((procedure, index) => {
                          delete procedure.sessaoId;
                          return {
                            ...procedure,
                            'ans:sequencialItem': index + 1 // Define o sequencialItem como index + 1
                          };
                        })
                      }
                    }),
                    ...(orcamento.tipo_xml_sadt === false && {
                      'ans:valorTotal': {
                        ...(totalValuePerBudget[id].valorProcedimentos > 0 && { 'ans:valorProcedimentos': totalValuePerBudget[id].valorProcedimentos.toFixed(2) }),
                        ...(totalValuePerBudget[id].valorDiarias > 0 && { 'ans:valorDiarias': totalValuePerBudget[id].valorDiarias.toFixed(2) }),
                        ...(totalValuePerBudget[id].valorTaxasAlugueis > 0 && { 'ans:valorTaxasAlugueis': totalValuePerBudget[id].valorTaxasAlugueis.toFixed(2) }),
                        ...(totalValuePerBudget[id].valorTotalGeral > 0 && { 'ans:valorTotalGeral': totalValuePerBudget[id].valorTotalGeral.toFixed(2) }),
                      },
                    }),
                    ...(filteredExpenses.length > 0 && {
                      'ans:outrasDespesas': {
                        'ans:despesa': filteredExpenses.map((despesa, index) => {
                          delete despesa.sessaoId;
                          return {
                            ...despesa,
                            'ans:sequencialItem': index + 1 // Define o sequencialItem como index + 1
                          };
                        })
                      }
                    }),
                    ...(orcamento.tipo_xml_sadt === true && {
                      'ans:valorTotal': {
                        ...(totalValuePerBudget[id].valorProcedimentos > 0 && { 'ans:valorProcedimentos': totalValuePerBudget[id].valorProcedimentos.toFixed(2) }),
                        ...(totalValuePerBudget[id].valorDiarias > 0 && { 'ans:valorDiarias': totalValuePerBudget[id].valorDiarias.toFixed(2) }),
                        ...(totalValuePerBudget[id].valorTaxasAlugueis > 0 && { 'ans:valorTaxasAlugueis': totalValuePerBudget[id].valorTaxasAlugueis.toFixed(2) }),
                        ...(totalValuePerBudget[id].valorTotalGeral > 0 && { 'ans:valorTotalGeral': totalValuePerBudget[id].valorTotalGeral.toFixed(2) }),
                      },
                    }),
                  }
                })
              }
            }
          }
        }
      };

      const root = create(obj)
      const xml = root.end({ prettyPrint: true, headless: true });

      const inicioCabecalho = xml.indexOf("<ans:cabecalho>");
      const fimCabecalho = xml.indexOf("</ans:cabecalho>");
      const cabecalho = xml.substring(inicioCabecalho, fimCabecalho + "</ans:cabecalho>".length);

      const inicioPrestador = xml.indexOf("<ans:prestadorParaOperadora>");
      const fimPrestador = xml.indexOf("</ans:prestadorParaOperadora>");
      const prestadorParaOperadora = xml.substring(inicioPrestador, fimPrestador + "</ans:prestadorParaOperadora>".length);

      const conteudoParaHash = cabecalho + prestadorParaOperadora;
      let conteudoTexto = conteudoParaHash.replace(/\n/g, '').replace(/>\s+</g, '').replace(/<[^>]+>/g, '').trim();

      const hash = md5(conteudoTexto);
      var epilogo = { 'ans:epilogo': { 'ans:hash': hash } };
      obj['ans:mensagemTISS']['ans:epilogo'] = epilogo['ans:epilogo'];
      const rootWithEpilogue = create(obj)
      const xmlWithEpilogue = rootWithEpilogue.end({ prettyPrint: true, headless: true });
      const xmlWithEncoding = `<?xml version="1.0" encoding="iso-8859-1"?>\n${xmlWithEpilogue}`;
      const nomePaciente = cleanText(empresa[0].paciente.replace(/\s/g, "_"))
      const nomeConvenio = cleanText(empresa[0].convenio.replace(/\s/g, "_"))
      const now = new Date()
      const empresaIds = empresa.map(empresa => empresa.id).join('_');
      this.selectedBudgets.length > 1 ?
        this.downloadXML(xmlWithEncoding, `XML_${nomeConvenio}_${this.formatDateNormal(now)}_Contas_${empresaIds}.xml`)
        : this.downloadXML(xmlWithEncoding, `XML_${nomePaciente}_${nomeConvenio}_${this.formatDateNormal(empresa.data_inicio)}_ate_${this.formatDateNormal(empresa.data_fim)}_Contas_${empresaIds}.xml`)

      this.updateXmlGerado();

      return this.valorTotalGeral;
    },

    async updateXmlGerado() {
      for (const empresa of this.empresa) {
        const fields = {
          paciente: Number(empresa.paciente_id),
          xml_gerado_agrupado: true,
          xml_gerado: true
        };

        try {
          await api.put(`atendimentos/sessoes_put_xml/${empresa.id}/`, fields);
        } catch (error) {
          console.error('Erro ao atualizar o campo xml_gerado:', error);
          this.$toast.error('Erro ao atualizar o campo xml_gerado.');
        }
      }
    },
    downloadXML(xml, filename) {
      try {
        // Cria um Blob com os dados XML
        const blob = new Blob([xml], { type: 'application/xml' });

        // Cria um URL para o Blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento de link temporário
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;

        // Adiciona o link ao documento e dispara o download
        document.body.appendChild(a);
        a.click();

        // Limpa e remove o link após o download
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // Mensagem de sucesso
        // this.$toast.success('O download do XML foi realizado com sucesso!');
      } catch (error) {
        console.error('Erro ao salvar o arquivo:', error);
        this.$toast.error("Erro ao salvar o arquivo: " + error.message);
      }
    },
    formatDateNormal(value) {
      if (!value) return "";
      return moment(value).format("DD_MM_YYYY");
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const requests = this.selectedBudgets.map(id => api.get(`atendimentos/relatorio-orcamento/?orcamento=${id}`));
          const responses = await Promise.all(requests);
          const data = responses.map(response => response.data);
          this.empresa = data.flat();
          // console.log('this.empresa ', this.empresa)
        } catch (error) {
          console.error('Erro ao buscar os dados do orçamento:', error);
        }
      }
    },
    async getProcedimento() {
      this.procedimentos = [];
      this.procedimentos2 = [];

      if (this.selectedBudgets.length > 0) {
        try {
          const procedimentosPromises = this.selectedBudgets.map(async (orcamentoId) => {
            const response = await api.get(`atendimentos/evolucoesxml/?sessao=${orcamentoId}`);
            const data = response.data.filter(item =>
              item?.ativo === true &&
              item?.cobranca === true &&
              (item?.assinatura !== null || item?.procedimento.especial === true) &&
              item.procedimento.procedimento_avaliacao === false &&
              item?.valor_cobranca !== null &&
              item?.desconto !== 100 &&
              item?.valor_cobranca !== 0 &&
              item?.cobranca_parcial === false
            );
            // console.log(data)
            // Filtrar e separar os procedimentos
            const procedimentos = data.filter(item => (item?.procedimento.especial === false ));
            const procedimentos2 = data.filter(item => (item?.procedimento.especial === true ));

            return { orcamentoId, procedimentos, procedimentos2 };
          });

          const responses = await Promise.all(procedimentosPromises);

          // Atribuir os dados filtrados aos respectivos arrays de procedimentos
          responses.forEach((response) => {
            const { orcamentoId, procedimentos, procedimentos2 } = response;
            this.procedimentos[orcamentoId] = procedimentos;
            this.procedimentos2[orcamentoId] = procedimentos2;
          });

          // Verificar se todos os tipos de guia são iguais
          // const allTiposGuia = Object.values(this.procedimentos).flat().map(item => item?.procedimento.tipoguia);
          // this.tipoGuiaUnico = new Set(allTiposGuia);
          // const [value] = [...this.tipoGuiaUnico];
          // this.tipoGuia = value;

          // console.log('Tipo da guia -->', value);
          // console.log('getProcedimento -->', this.procedimentos);
          // console.log('getProcedimento2 -->', this.procedimentos2);
        } catch (error) {
          console.error('Erro ao buscar os dados dos procedimentos:', error);
        }
      }
    },
    async getTaxa() {
      this.taxas = [];

      if (this.selectedBudgets.length > 0) {
        try {
          const requests = this.selectedBudgets.map(id => api.get(`atendimentos/taxas/?orcamento=${id}`));
          const responses = await Promise.all(requests);

          responses.forEach((response, index) => {
            const id = this.selectedBudgets[index];
            this.taxas[id] = response.data.filter(item => item?.ativo && item.desconto_taxa !== 100);
          });

          // console.log('Taxas:', this.taxas);
        } catch (error) {
          console.error('Erro ao buscar os dados da taxa:', error.response ? error.response.data : error.message);
        }
      }
    },
    async getMatMed() {
      this.matmed = [];

      if (this.selectedBudgets.length > 0) {
        try {
          const requests = this.selectedBudgets.map(id => api.get(`atendimentos/matmeds/?orcamento=${id}`));
          const responses = await Promise.all(requests);

          responses.forEach((response, index) => {
            const id = this.selectedBudgets[index];
            this.matmed[id] = response.data.filter(item => item?.ativo && item.desconto_matmed !== 100);
          });

          // console.log('MatMed', this.matmed);
        } catch (error) {
          console.error('Erro ao buscar os dados da taxa:', error.response ? error.response.data : error.message);
        }
      }
    },
    async getEquipamentos() {
      this.equipamentos = [];

      if (this.selectedBudgets.length > 0) {
        try {
          const requests = this.selectedBudgets.map(id => api.get(`atendimentos/equipamentos/?orcamento=${id}`));
          const responses = await Promise.all(requests);

          responses.forEach((response, index) => {
            const id = this.selectedBudgets[index];
            this.equipamentos[id] = response.data.filter(item => item?.ativo && item.desconto_equipamento !== 100);
          });

          // console.log('Equipamentos:', this.equipamentos);
        } catch (error) {
          console.error('Erro ao buscar os dados do equipamento:', error.response ? error.response.data : error.message);
        }
      }
    },
    async getPacote() {
      this.pacotes = [];

      if (this.selectedBudgets.length > 0) {
        try {
          const requests = this.selectedBudgets.map(id => api.get(`atendimentos/pacotes/?orcamento=${id}`));
          const responses = await Promise.all(requests);

          responses.forEach((response, index) => {
            const id = this.selectedBudgets[index];
            this.pacotes[id] = response.data.filter(item => item?.ativo && item.desconto_pacote !== 100 && item.valor_cobranca_final_pacote != 0 && item.valor_cobranca_final_pacote != null);
          });

          // console.log('Pacotes:', this.pacotes);
        } catch (error) {
          console.error('Erro ao buscar os dados do pacote:', error.response ? error.response.data : error.message);
        }
      }
    }
  },
};
</script>